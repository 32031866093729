<template>
	<v-overlay :visible="visible" @close="close()">
		<div class="flex space-between align-center mb-50">
			<div>
				<h1 class="mb-10">Bólkur</h1>

				<p class="color-grey">Stovna ein bólk til SMS skipanina</p>
			</div>

			<div class="overlay-close" @click.prevent="close()"></div>
		</div>

		<div class="mb-30">
			<label class="mb-10">Navn á bólki</label>

			<input v-model="name" type="text" @keydown.enter="save" />
		</div>

		<div class="mb-30">
			<label class="mb-10">Legg kunda afturat</label>

			<CustomerSearchInput @change="customerSearchChange" />

			<div class="small-text color-red mt-10 px-5" v-if="errorMessage">{{ errorMessage }}</div>
		</div>

		<div class="mb-30">
			<label class="mb-10">Kundar</label>

			<div class="customers">
				<div class="customer" v-for="(customer, index) in customers" :key="index">
					{{ customer.name }}

					<div class="customer-remove" @click="removeCustomer(customer.id)"><i class="fas fa-trash"></i></div>
				</div>
			</div>

			<div v-if="!customers.length" class="color-grey">Ongin kundi valdur</div>
		</div>

		<div class="flex space-between">
			<div v-if="this.editingId !== null" class="btn background-red inline-block" @click="deleteGroup">Strika bólk</div>

			<div class="btn inline-block" :class="{ disabled: !valid || loading }" @click="save">{{ loading ? 'Bíða við...' : 'Dagfør' }}</div>
		</div>
	</v-overlay>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';
import CustomerSearchInput from '@/components/CustomerSearchInput.vue';
import axios from 'axios';

export default {
	name: 'MessageGroupOverlay',

	props: {
		visible: Boolean,
		link: Boolean,
		agreement: Object,
	},

	components: {
		VOverlay,
		CustomerSearchInput,
	},

	data() {
		return {
			customers: [],
			name: '',
			editingId: null,
			loading: false,
			errorMessage: '',
		};
	},

	computed: {
		valid() {
			return this.name;
		},
	},

	methods: {
		setData(data) {
			this.editingId = data.id;
			this.name = data.name;
			this.customers = data.customers;
		},

		save() {
			if (!this.valid || this.loading) {
				return;
			}

			const data = {
				name: this.name,
				customerIds: this.customers.map((customer) => customer.id),
			};

			// if we are editing, send a PUT
			if (this.editingId !== null) {
				axios
					.put(`/message-groups/${this.editingId}`, data)
					.then(() => {
						this.$emit('update', {
							id: this.editingId,
							name: this.name,
							customers: this.customers,
						});

						this.close();
					})
					.catch((error) => {
						console.log('ERROR', error);
						// TODO: Something went wrong. Please try again.
					});

				return;
			}

			// if we are creating a new group, send a POST
			axios
				.post('/message-groups', data)
				.then((response) => {
					this.$emit('save', {
						id: response.data.id,
						name: this.name,
						customers: this.customers,
					});

					this.close();
				})
				.catch((error) => {
					console.log('ERROR', error);
					// TODO: Something went wrong. Please try again.
				});
		},

		customerSearchChange(e) {
			const customer = this.customers.find((customer) => customer.id == e.id);

			if (customer) {
				this.errorMessage = 'Kundin er longu í listanum';

				setTimeout(() => {
					this.errorMessage = '';
				}, 3000);

				return;
			}
			this.customers.push(e);
		},

		removeCustomer(id) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.customers = this.customers.filter((customer) => customer.id !== id);
		},

		deleteGroup() {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			if (this.editingId === null) {
				return;
			}

			this.loading = true;

			axios
				.delete(`/message-groups/${this.editingId}`)
				.then(() => {
					this.$emit('delete', this.editingId);

					this.close();
				})
				.catch((error) => {
					console.log('ERROR', error);
					// TODO: Something went wrong. Please try again.
				})
				.finally(() => {
					this.loading = false;
				});
		},

		close() {
			this.$emit('close');
			this.name = '';
			this.customers = [];
			this.loading = false;
			this.editingId = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.customers {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	max-height: 320px;
	overflow-y: auto;
	overflow-x: hidden;
}

.customer {
	position: relative;

	display: inline-block;

	padding: 16px;

	background: white;

	border: 1px solid #ddd;
	border-radius: 5px;

	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05);

	width: 100%;

	.customer-remove {
		position: absolute;
		top: 50%;
		right: 4px;
		width: 32px;
		height: 32px;

		display: grid;
		place-items: center;

		color: var(--color-grey);

		font-size: 16px;
		line-height: 1;

		border-radius: 50%;

		transform: translateY(-50%);

		cursor: pointer;

		opacity: 0;

		transition: 0.2s color ease, 0.2s background-color ease;

		&:hover {
			color: var(--color-red);
			background-color: var(--color-light-grey-300);
		}
	}

	&:hover {
		.customer-remove {
			opacity: 1;
		}
	}
}
</style>
