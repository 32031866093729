<template>
	<div>
		<div class="search-input relative" :class="{ 'z-100': customerSearchString.length > 0 }">
			<input type="text" v-model="customerSearchString" @keyup="search" @keydown.esc.prevent="customerSearchString = ''" :disabled="disabled" />

			<div class="icon-search"></div>

			<div v-if="customerSearchString.length > 0 && customers.length > 0" class="search-results absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10" :class="{ 'z-100': customerSearchString.length > 0 }">
				<div class="py-15 px-20 border-bottom hover:background-light-grey-100 last-child:border-none cursor-pointer" v-for="(customer, index) in customers" :key="index" @click.prevent="applySearchResult(customer)">
					<p class="mb-10">{{ customer.name }}</p>

					<p class="color-grey">
						<span v-if="customer.address">{{ customer.address }}, </span><span v-if="customer.zip">{{ customer.zip }} </span><span v-if="customer.city">{{ customer.city }}</span>
					</p>
				</div>
			</div>

			<div v-if="customerSearchString.length > 0 && customers.length == 0" class="search-results absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10" :class="{ 'z-100': customerSearchString.length > 0 }">
				<div class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none" v-for="(customer, index) in customers" :key="index">
					<p class="mb-5">Ongin fyritøka funnin</p>

					<p class="color-grey">
						Vit hava leitað eftir <strong>{{ customerSearchString }}</strong>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'CustomerSearchInput',

	props: {
		max: Number,
		disabled: Boolean,
	},

	data() {
		return {
			customerSearchString: '',

			customers: [],

			customer: {},
		};
	},

	mounted() {
		window.addEventListener('click', () => {
			this.customer.customerSearchString = '';
		});
	},

	watch: {
		customer: {
			deep: true,

			handler() {
				if (this.customer.name) {
					this.$emit('change', this.customer);

					return;
				}
			},
		},
	},

	methods: {
		search() {
			axios.get(`/customers/search?q=${this.customerSearchString}`).then((response) => {
				if (this.max) {
					this.customers = response.data.slice(0, this.max);
					return;
				}

				this.customers = response.data;
			});
		},

		applySearchResult(customer) {
			this.customerSearchString = '';

			this.customer = customer;
		},
	},
};
</script>
