<template>
	<main class="container">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Boð</h1>

				<p class="color-grey">Send kundunum eini boð</p>
			</div>
		</div>

		<div class="grid grid-3-5 grid-gap-20">
			<div v-if="loading">
				<div class="flex justify-center">
					<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
				</div>
			</div>

			<div v-if="!loading">
				<label for="message" class="mb-10">Bólkar</label>

				<div v-if="!messageGroups.length" class="mb-20 color-grey">Ongin bólkur er stovnaður</div>

				<div class="message-groups">
					<div class="message-group-search">
						<input type="text" v-model="query" placeholder="Leita..." />

						<i class="fas fa-search"></i>
					</div>

					<div class="message-group-container" v-if="filteredMessageGroups.length">
						<div
							v-for="(messageGroup, index) in filteredMessageGroups"
							:key="`messageGroup${index}`"
							class="message-group mb-10"
							:class="{ active: selectedGroups && selectedGroups.includes(index) }"
							@click="selectGroup(index)"
						>
							<div class="message-group-title">{{ messageGroup.name }}</div>

							<div v-if="messageGroup.id" class="message-group-edit" @click="editMessageGroup($event, messageGroup)">
								<i class="fas fa-cog"></i>
							</div>
						</div>
					</div>

					<div class="flex justify-center">
						<a href="#" class="btn add-message-group" @click="messageGroup.visible = true"><i class="fas fa-plus"></i></a>
					</div>
				</div>
			</div>

			<div>
				<label for="message" class="mb-10">Boð</label>

				<textarea v-model="message" cols="30" rows="10" :maxlength="sendSMS ? 140 : -1" class="no-resize"></textarea>
				<div class="mb-20 text-right color-grey">
					<span v-if="sendSMS">{{ message.length }} / 140</span>
				</div>

				<!-- SMS: 7bit: 160 tekin  -->
				<!-- SMS: 8bit: 140 tekin -->
				<!-- SMS: 16bit: 70 tekin -->

				<div class="buttons flex space-between align-center">
					<div class="message-types">
						<label>
							<input type="checkbox" v-model="sendEmail" />

							Send Teldupost
						</label>

						<label>
							<input type="checkbox" v-model="sendSMS" />

							Send SMS
						</label>
					</div>

					<button
						@click.prevent="sendMessage"
						:disabled="messageLoading || !message || !selectedGroups.length || (!sendEmail && !sendSMS)"
						class="btn"
						:class="{ 'opacity-50': messageLoading || !message || !selectedGroups.length || (!sendEmail && !sendSMS) }"
					>
						<i v-show="messageLoading" class="fas fa-spinner fa-spin mr-5"></i>

						Send boð
					</button>
				</div>
			</div>
		</div>

		<MessageGroupOverlay
			ref="messageGroupOverlay"
			:visible="messageGroup.visible"
			@save="createMessageGroup"
			@update="updateMessageGroup"
			@delete="deleteMessageGroup"
			@close="messageGroup.visible = false"
		/>
	</main>
</template>

<script>
import MessageGroupOverlay from '@/components/MessageGroupOverlay.vue';

import axios from 'axios';

export default {
	name: 'Messages',

	components: {
		MessageGroupOverlay,
	},

	data() {
		return {
			loading: true,

			messageLoading: false,

			customers: [],

			query: '',

			messageGroups: [],

			selectedGroups: [],

			messageGroup: {
				visible: false,
			},

			message: '',
			sendEmail: false,
			sendSMS: true,
		};
	},

	async mounted() {
		// GET all message groups
		axios
			.get('/message-groups')
			.then((response) => {
				this.messageGroups = response.data;
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	computed: {
		filteredMessageGroups() {
			if (!this.query) {
				return this.messageGroups;
			}

			return this.messageGroups.filter((messageGroup) => messageGroup.name.toLowerCase().includes(this.query.toLowerCase()));
		},
	},

	watch: {
		sendSMS: function (sendSMS) {
			if (sendSMS && this.message.length > 70) {
				this.message = this.message.substring(0, 70);
			}
		},
	},

	methods: {
		selectGroup(index) {
			if (this.selectedGroups.includes(index)) {
				this.selectedGroups = this.selectedGroups.filter((group) => group != index);

				return;
			}

			this.selectedGroups = [...this.selectedGroups, index];
		},

		createMessageGroup(messageGroup) {
			this.messageGroups.push(messageGroup);
		},

		deleteMessageGroup(id) {
			const index = this.messageGroups.findIndex((messageGroup) => messageGroup.id === id);

			if (this.selectedGroups.includes(index)) {
				this.selectedGroups = this.selectedGroups.filter((group) => group == index);
			}

			this.messageGroups = this.messageGroups.filter((messageGroup) => messageGroup.id !== id);
		},

		updateMessageGroup(updatedMessageGroup) {
			const index = this.messageGroups.findIndex((messageGroup) => messageGroup.id === updatedMessageGroup.id);

			this.messageGroups[index].name = updatedMessageGroup.name;
			this.messageGroups[index].customers = updatedMessageGroup.customers;
		},

		editMessageGroup(e, messageGroup) {
			e.stopImmediatePropagation();

			this.$refs.messageGroupOverlay.setData({
				id: messageGroup.id,
				name: messageGroup.name,
				customers: messageGroup.customers,
			});

			this.messageGroup.visible = true;
		},

		sendMessage() {
			if (!confirm('Ert tú vís/ur í, at boðini eru klár at senda?')) {
				return;
			}

			this.messageLoading = true;

			const message = this.message;
			const channels = [];

			if (this.sendSMS) {
				channels.push('sms');
			}

			if (this.sendEmail) {
				channels.push('email');
			}

			let promises = [];

			for (const selectedGroup of this.selectedGroups) {
				const messageGroupId = this.messageGroups[selectedGroup]?.id || null;

				promises = [
					...promises,
					axios.post('/messages', {
						message,
						messageGroupId,
						channels,
					}),
				];
			}

			Promise.all(promises)
				.then((value) => {
					console.log(value);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.messageLoading = false;
					this.selectedGroups = [];
					this.message = '';
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.message-groups {
	display: flex;
	flex-direction: column;
}

.message-group-search {
	position: relative;

	margin-bottom: 10px;

	i {
		position: absolute;
		top: 50%;
		right: 16px;

		transform: translateY(-50%);

		color: var(--color-grey);

		pointer-events: none;
	}
}

.message-group {
	display: flex;
	justify-content: space-between;

	color: #383838;
	background: white;

	font-size: 1.2rem;

	border: 1px solid #e8e8e8;
	border-radius: 5px;

	transition: 0.1s box-shadow ease, 0.1s background-color ease, 0.1s border ease;

	cursor: pointer;

	&:hover {
		box-shadow: 0 2px 10px rgba(black, 0.15);
	}

	&.active {
		border: 1px solid var(--color-blue-600);
		background: var(--color-blue);

		color: white;

		.message-group-edit {
			padding: 18px;

			color: white;

			border-left: 1px solid var(--color-blue-700);

			cursor: pointer;

			transition: 0.1s background-color ease;

			&:hover {
				background-color: var(--color-blue-600);
			}
		}
	}

	.message-group-title {
		padding: 18px;
	}

	.message-group-edit {
		display: flex;
		justify-content: center;
		align-items: center;

		padding: 18px;

		color: #484848;

		border-left: 1px solid #e8e8e8;

		transition: 0.1s background-color ease;

		i {
			transition: 0.2s transform ease;
		}

		&:hover {
			background-color: #f2f2f2;

			i {
				transform: rotate(45deg);
			}
		}
	}
}

.message-group-container {
	max-height: 420px;

	overflow-y: auto;
}

.message-types {
	label {
		margin-bottom: 16px;
		color: var(--color-grey);

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.add-message-group {
	padding: 16px 20px;

	&:hover {
		background-color: var(--color-blue-600);
	}
}

.select select {
	padding: 12px;

	background: white;

	border: 1px solid #ddd;
	border-radius: 8px;
}
</style>
